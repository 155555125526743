import Modal from "react-modal"

import { ReactComponent as SuccessIcon } from "../assets/images/icon-success.svg"
import { ReactComponent as ErrorIcon } from "../assets/images/icon-error.svg"
import loaderStyles from "../styles/loader.module.css";

Modal.setAppElement('#root')

export enum PaymentModalType {
  Loading = 'Loading',
  TapCard = 'TapCard',
  Error = 'Error',
  Success = 'Success',
}

export interface IPaymentModalProps {
  open: boolean,
  type?: PaymentModalType,
  message?: string,
}

const PaymentModal: React.FC<IPaymentModalProps> = ({
  open = false,
  type = PaymentModalType.Loading,
  message = null,
}) => {
  let content = null;

  switch(type) {
    case PaymentModalType.Loading:
      content = <>
        <svg className={loaderStyles.circular_loader} viewBox="25 25 50 50">
          <circle className={loaderStyles.loader_path} cx="50" cy="50" r="20" fill="none" stroke="#70c542" strokeWidth="2" />
        </svg>
        <p className="mt-8">Loading</p>
      </>
      break;
    case PaymentModalType.TapCard:
      content = <>
        <svg className={loaderStyles.circular_loader} viewBox="25 25 50 50">
          <circle className={loaderStyles.loader_path} cx="50" cy="50" r="20" fill="none" stroke="#70c542" strokeWidth="2" />
        </svg>
        <p className="mt-8">Please tap your card on the terminal</p>
      </>
      break;
    case PaymentModalType.Error:
      content = <>
        <ErrorIcon className="w-24 h-24" />
        <p className="mt-8">{message ? `: ${message}` : 'Processing error.'}<br /><br />Please try again.</p>
      </>
      break;
    case PaymentModalType.Success:
      content = <>
        <SuccessIcon className="w-24 h-24" />
        <p className="mt-8">Thank you!<br />Payment complete</p>
      </>
      break;
  }

  return (
    <Modal
      isOpen={open}
      overlayClassName="modal-overlay"
      className="payment-modal"
      contentLabel="Disclosure"
    >
      <section className="payment">
        {content}
      </section>

      <section className="payment-disclosure">
        <p>Cost per tree is approximate. Your donation will be made to Conservation International, with proceeds granted to forest restoration projects fulfilled by both Conservation International and World Resource Institute. Conservation International has been recognized by the IRS as a 501(c)(3) charitable organization. Your donation (less any applicable standard card processing fees) will be paid directly, and typically without delay, to the Charity. Mastercard is not serving as a professional fundraiser on behalf of the Charity and Mastercard does not charge charities to be listed on this platform. Mastercard is serving as the donation platform provider solely to help facilitate the donation. No personally identifiable information is collected in connection with this donation to the Charity. Your donation is subject to the Terms of Use for the Mastercard Donation Service, which can be found at:  https://mstr.cd/TOU.  By tapping your card on the terminal, you are accepting the Terms of Use of the Mastercard Donation Service. Please consult your own tax advisor as to the possibility of deducting your donation, since Mastercard makes no representations or warranties as to any such deductibility. </p>
      </section>
    </Modal>
  )
}

export default PaymentModal