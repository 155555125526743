import { createContext, useReducer, Dispatch } from "react";
import { TravelType } from "../enums";
import { ITransportConfig } from "../items-config";

export interface IGlobalState {
  travelType?: TravelType,
  transports: ITransportConfig[],
  trees: number,
  co2Total: number,
  donationAmount: number,
}

type GlobalStateReducerPayload = TravelType | ITransportConfig

export enum GlobalStateReducerTypes {
  Reset = 'Reset',
  SetTravelType = 'SetTravelType',
  UpsertTransport = 'UpsertTransport',
}

const initialState: IGlobalState = {
  trees: 0,
  co2Total: 0,
  donationAmount: 0,
  transports: [],
}

const Reducer = (state: IGlobalState, action: { type: GlobalStateReducerTypes, payload: GlobalStateReducerPayload }): IGlobalState => {
  function calculate(state: IGlobalState): IGlobalState {
    state.trees = state.transports.reduce((acc, transport) => acc + transport.trees, 0)
    state.co2Total = state.transports.reduce((acc, transport) => acc + transport.co2, 0)
    state.donationAmount = state.transports.reduce((acc, transport) => acc + transport.donation, 0)
    return state
  }

  switch (action.type) {
    case GlobalStateReducerTypes.Reset:
      return initialState

    case GlobalStateReducerTypes.SetTravelType:
      return {
        ...state,
        travelType: action.payload as TravelType,
      }

    case GlobalStateReducerTypes.UpsertTransport:
      const transports = Array.from((state.transports || []))
        .filter((transport) => transport.type !== (action.payload as ITransportConfig).type)

      transports.push(action.payload as ITransportConfig)

      return calculate({
        ...state,
        transports,
      })

    default:
      return state
  }
};

const Store: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>
      {children}
    </Context.Provider>
  )
};

export const Context = createContext({
  state: initialState,
  dispatch: {} as Dispatch<any>,
})
export default Store;