import { FC, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import Layout from "../shared/Layout"
import Content from "../shared/Content"
import Header from "../shared/Header"
import ScreensaverVideo from "../assets/videos/screensaver.webm"
import { Context, GlobalStateReducerTypes } from "../context/GlobalStateContext"

interface IHomeProps { }

const Home: FC<IHomeProps> = () => {
  const navigate = useNavigate()
  const { dispatch } = useContext(Context);

  useEffect(() => {
    dispatch({
      type: GlobalStateReducerTypes.Reset,
    })
  }, [dispatch]);

  return (
    <Layout onClick={() => { navigate('/options') }}>
      <Header />

      <Content leftPadding={false} rightPadding={false} topPadding={false} bottomPadding={false}>
        <video src={ScreensaverVideo} muted loop autoPlay className="w-full h-full object-cover" />
      </Content>
    </Layout>
  );
};

export default Home;
