export interface ICalculatorProps  {
  value: number,
}

const Footer: React.FC<ICalculatorProps> = ({
  value,
}): JSX.Element => {
  

  return (
    <section key={value} className="flex flex-col justify-center items-center rounded-full w-[30rem] h-[30rem] bg-white relative shadow-sm"
    >
      <div className="flex flex-col justify-center items-center w-[24rem] h-[24rem] rounded-full bg-white">
        <span className="text-7.5xl">{value}</span>
        <span className="text-4xl leading-tight text-center mt-4">kg CO2<br />Estimate</span>
      </div>

      <svg height="300" width="300" viewBox="0 0 300 300" className="w-full h-full absolute z-10 -rotate-90">
        <circle className="circle stroke-eco-green" cx="50%" cy="50%" clipPath="circle()" r="50%" strokeWidth="70" fillOpacity="0" />
      </svg>

    </section>
  )
}

export default Footer