import { FC, useCallback, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer'
import Calculator from "../shared/Calculator"
import Layout from "../shared/Layout"
import Content from "../shared/Content"
import Header from "../shared/Header"
import Footer from "../shared/Footer"
import ActionButton from "../shared/ActionButton"
import { Context } from "../context/GlobalStateContext"

import FootprintImg from "../assets/images/total-footprint.webp"
import TreeIcon from "../assets/images/icon-tree.png"

const idleTimeout = Number(process.env.REACT_APP_IDLE_TIMEOUT)

interface IFootprintProps { }

const Footprint: FC<IFootprintProps> = () => {
  const navigate = useNavigate()
  const { state } = useContext(Context);

  // Idle redirect
  const idleTimeoutHandler = useCallback(() => {
    navigate('/')
  }, [navigate])

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: idleTimeoutHandler
  })

  useEffect(() => {
    if (!state.travelType) {
      navigate('/');
    }
  }, [state, navigate]);

  const selection = useCallback(() => {
    navigate('/planet-coalition')
  }, [navigate]);

  return (
    <Layout>
      <Header />

      <Content>
        <h1 className="text-7.5xl mb-16 tracking-wider">Your carbon footprint</h1>

        <section className="flex flex-row justify-between items-start pr-2 w-full">
          <img src={FootprintImg} alt="Total Footprint" className="object-contain aspect-[487/455]" />

          <div className="flex flex-col">
            <Calculator value={state.co2Total} />
          </div>
        </section>

        <section className="flex flex-row items-center bg-white rounded-3xl p-9 mt-16 text-4xl">
          <img src={TreeIcon} alt="Tree" className="object-contain w-26" />
          <span className="grow text-center">
            This can be compensated by:
            <span className="font-bold text-5xl ml-6">{state.trees}</span>
            &nbsp;
            <span className="font-bold">{state.trees === 1 ? 'tree' : 'trees'}*</span>
          </span>
        </section>

        <div className="text-center mt-8 text-lg">* 1 tree is equivalent to 20 kg of carbon captured over 5 years</div>

        <section className="mt-36 w-full text-center">
          <ActionButton className="w-1/2" onClick={selection}>NEXT</ActionButton>
        </section>

      </Content>

      <Footer />
    </Layout>
  );
};

export default Footprint
