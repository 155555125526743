import { FC, useCallback, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer'
import Calculator from "../shared/Calculator"
import Layout from "../shared/Layout"
import Content from "../shared/Content"
import Header from "../shared/Header"
import Footer from "../shared/Footer"
import ActionButton from "../shared/ActionButton"
import { Context, GlobalStateReducerTypes } from "../context/GlobalStateContext"
import { TaxiConfig } from "../items-config"

import TaxiImg from "../assets/images/taxi.webp"

const idleTimeout = Number(process.env.REACT_APP_IDLE_TIMEOUT)

interface ITaxiProps { }

const Taxi: FC<ITaxiProps> = () => {
  const navigate = useNavigate()
  const { state, dispatch } = useContext(Context);

  // Idle redirect
  const idleTimeoutHandler = useCallback(() => {
    navigate('/')
  }, [navigate])

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: idleTimeoutHandler
  })

  useEffect(() => {
    if (!state.travelType) {
      navigate('/');
    }
  }, [state, navigate]);

  const selection = useCallback(() => {
    dispatch({
      type: GlobalStateReducerTypes.UpsertTransport,
      payload: TaxiConfig,
    })

    navigate('/footprint')
  }, [dispatch, navigate]);

  return (
    <Layout>
      <Header />

      <Content>
        <h1 className="text-7.5xl mb-36 tracking-wider">Car / taxi to the event</h1>

        <section className="flex flex-row justify-between items-start pr-2 w-full">
          <img src={TaxiImg} alt="Taxi" className="object-contain aspect-[445/526]" />

          <div className="flex flex-col">
            <Calculator value={TaxiConfig.co2} />
            <ActionButton className="mt-36" onClick={() => selection()}>NEXT</ActionButton>
          </div>
        </section>

      </Content>

      <Footer />
    </Layout>
  );
};

export default Taxi;
