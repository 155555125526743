import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { enableAutoPageviews } from './utils/analytics'

serviceWorkerRegistration.register();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://06f8c6db8bfe4370b5df49c59d97afa3@o1413651.ingest.sentry.io/4504061907173376",

    integrations: [
      new BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

enableAutoPageviews()

// Can also use with React Concurrent Mode
// ReactDOM.createRoot(document.getElementById('root')).render(<App />);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
