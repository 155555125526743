import { useCallback, useState } from "react"
import { capturePaymentIntent, collectPaymentMethod, createPaymentIntent, processPayment } from "../utils/stripe"

export interface IStripeStateLoading {
  status: 'loading'
}

export interface IStripeStateTap {
  status: 'tap'
}

export interface IStripeStateSuccess {
  status: 'success'
  brand: string | null | undefined
}

export interface IStripeStateError {
  status: 'error',
  error: string
}

export type StripeState = IStripeStateLoading | IStripeStateTap | IStripeStateSuccess | IStripeStateError

const useStripe = () => {
  const [state, setState] = useState<StripeState>({
    status: 'loading',
  })

  const pay = useCallback(async (amount: number, currency: string = 'cad'): Promise<void> => {
    try {
      setState({ status: 'loading' })

      console.debug('Getting/creating terminal instance')

      const amountCents = amount * 100

      console.debug('Creating payment intent for %d cents', amountCents)

      const clientSecret = await createPaymentIntent({
        amount: amountCents,
        currency,
      })

      console.debug('Created payment intent')

      setState({ status: 'tap' })

      console.debug('Collecting payment method')

      const intent = await collectPaymentMethod(clientSecret)

      console.debug('Collecting payment method succeeded', intent)

      console.debug('Processing payment')

      const { id: paymentIntentId } = await processPayment(intent)
      
      console.log('Processed payment. Intent ID: %s', paymentIntentId)

      console.debug('Capturing payment intent')

      const paymentIntent = await capturePaymentIntent(paymentIntentId)

      console.log('Captured payment intent', paymentIntent)

      const { charges: {
        data: [ charge ],
      } } = paymentIntent

      if (charge.status !== 'succeeded') {
        console.error('Captures payment intent was not successful')
        throw new Error('Payment capture intent was not successful');
      }

      console.debug('Payment succeeded')

      const brand: string | null | undefined = charge.payment_method_details?.card_present?.brand
      
      setState({
        status: 'success',
        brand,
      })
    } catch(error: any) {
      console.error('Error', error)

      setState({
        status: 'error',
        error: error.toString()
      })
    }
  }, [])

  return {
    state,
    pay,
  }
}

export default useStripe