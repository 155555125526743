import { useState, useContext, useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer'
import { trackEvent } from "../utils/analytics"
import SelectionButton from "../shared/SelectionButton"
import Calculator from "../shared/Calculator"
import Layout from "../shared/Layout"
import Content from "../shared/Content"
import Header from "../shared/Header"
import Footer from "../shared/Footer"
import { Context, GlobalStateReducerTypes } from "../context/GlobalStateContext"
import { FlightsConfig, ITransportConfig } from "../items-config"

import FlightImg from "../assets/images/flight.webp"
import BoardingPassImg from "../assets/images/boarding-pass.png"
import ActionButton from "../shared/ActionButton"
import { TravelType } from "../enums"

const idleTimeout = Number(process.env.REACT_APP_IDLE_TIMEOUT)

function BoardingPassIcon() {
  return (
    <img src={BoardingPassImg} alt="Boarding Pass" className="mt-4" />
  )
}

interface IFlightProps { }

const Flight: React.FC<IFlightProps> = () => {
  const navigate = useNavigate()
  const [selectedFlight, setSelectedFlight] = useState<ITransportConfig | null>(null)
  const { state, dispatch } = useContext(Context);
  
  // Idle redirect
  const idleTimeoutHandler = useCallback(() => {
    navigate('/')
  }, [navigate])

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: idleTimeoutHandler
  })

  useEffect(() => {
    if (!state.travelType) {
      navigate('/');
    }
  }, [state, navigate]);

  const selection = useCallback((typeIndex: number) => {
    const type = FlightsConfig[typeIndex];

    console.log('Selected flight #%d: %s', typeIndex, type.name);
    
    setSelectedFlight(FlightsConfig[typeIndex]);

    dispatch({
      type: GlobalStateReducerTypes.UpsertTransport,
      payload: FlightsConfig[typeIndex],
    });
  }, [dispatch]);

  const next = useCallback(() => {
    if (selectedFlight) {
      trackEvent('Selected Flight', { props: { flight: selectedFlight?.name } })
    }

    switch(state.travelType) {
      case TravelType.FlightAndTaxi:
        return navigate('/taxi');
      default:
        return navigate('/footprint');
    }
  }, [state.travelType, navigate, selectedFlight]);

  return (
    <Layout>
      <Header />

      <Content>
        <h1 className="text-7.5xl mb-16 tracking-wider">Where did you fly in<br />from?</h1>

        <section className="flex flex-row justify-between items-start pr-2 w-full">
          <section className="w-96">
            {FlightsConfig.map((flight, index) => (
              <SelectionButton
                key={flight.name}
                title={flight.name}
                content={<BoardingPassIcon />}
                onClick={() => { selection(index) }}
                selected={selectedFlight && selectedFlight.name === flight.name ? true : false}
                className="mb-8"
              />
            ))}
          </section>

          {
            selectedFlight === null
              ? <img src={FlightImg} alt="Flight" className="object-contain -mr-12" />
              : <div className="flex flex-col">
                  <Calculator value={selectedFlight.co2} />
                  <ActionButton className="mt-36" onClick={() => next()}>NEXT</ActionButton>
                </div>
          }
        </section>

      </Content>

      <Footer />
    </Layout>
  );
};

export default Flight;
