export enum ActionButtonTypes {
  Primary = "primary",
  Secondary = "secondary",
}
export interface IActionButtonProps {
  onClick?: () => void,
  type?: ActionButtonTypes,
  className?: string,
}

const ActionButton: React.FC<IActionButtonProps> = ({
  onClick,
  className = '',
  type = ActionButtonTypes.Primary,
  children,
}): JSX.Element => {
  let styleClasses = '';

  switch (type) {
    case ActionButtonTypes.Primary:
    default:
      styleClasses = 'text-white bg-eco-red';
      break;
    case ActionButtonTypes.Secondary:
      styleClasses = 'bg-white text-black';
      break;
  }

  return (
    <button className={`text-3xl w-full shadow-xl rounded-3xl ${styleClasses}
      p-6 transition-transform active:shadow-inner ${className}`}
      onClick={() => onClick && onClick()}
    >
      {children}
    </button>
  )
}

export default ActionButton