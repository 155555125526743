import { Link, useNavigate } from "react-router-dom"
import styles from '../styles/layout.module.css'
import { ReactComponent as BackIcon } from "../assets/images/icon-arrow-left.svg"
import { ReactComponent as RefreshIcon } from "../assets/images/icon-refresh.svg"

export interface IFooterProps  {
  
}

const Footer: React.FC<IFooterProps> = ({
  ...props
}): JSX.Element => {
  const navigate = useNavigate()

  return (
    <footer className={`bg-black text-white font-light text-2xl min-h-[var(--footer-height)]
      ${styles['layout-left-padding']} ${styles['layout-right-padding']}
      flex flex-row justify-between items-center`}
      {...props}
    >
      <button onClick={() => navigate(-1)} className="flex flex-row items-center active:mt-1">
        <BackIcon fill="white" className="mr-3" />
        Back
      </button>

      <section className="text-lg leading-6">
        The carbon footprint examples above are estimates based on the Åland<br />
        Index calculations provided by Doconomy AB, a company registered in<br />
        Sweden. The calculations are based on the transaction amount and<br />
        merchant category code. Donating a tree is honorary and does not confer<br />
        the rights to any specific tree. Learn more at www.pricelessplanet.org
      </section>

      <Link to="/" className="flex flex-row items-center active:mt-1">
        <RefreshIcon fill="white" className="mr-5" />
        Reset
      </Link>
    </footer>
  )
}

export default Footer