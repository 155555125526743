import { useNavigate } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer'
import Layout from "../shared/Layout"
import Content from "../shared/Content"
import Header from "../shared/Header"
import ThankYouVideo from "../assets/videos/Thankyou_BG.webm"
import { useCallback } from "react"

interface IThankYouProps { }

const ThankYou: React.FC<IThankYouProps> = () => {
  const navigate = useNavigate()
  
  const next = useCallback(() => {
    navigate('/event-points')
  }, [navigate])

  // Idle redirect. Should not trigger normally, but just in case
  useIdleTimer({
    timeout: 60_000,
    onIdle: next
  })

  return (
    <Layout onClick={next}>
      <Header />

      <Content leftPadding={false} rightPadding={false} topPadding={false} bottomPadding={false}>
        <video src={ThankYouVideo} onEnded={next} muted autoPlay className="w-full h-full object-cover" />
      </Content>

    </Layout>
  );
};

export default ThankYou;
