import { FC, useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer'
import { trackEvent } from '../utils/analytics'
import Layout from "../shared/Layout"
import Content from "../shared/Content"
import Header from "../shared/Header"
import Footer from "../shared/Footer"
import ActionButton from "../shared/ActionButton"

import CarbonApp from "../assets/images/carbon-app.webp"
import EventApp from "../assets/images/event-app.webp"

const idleTimeout = Number(process.env.REACT_APP_IDLE_TIMEOUT)

interface IEventPointsProps { }

function BulletPoint({
  index,
  children,
}: {
  index: number,
  children: React.ReactNode,
}) {
  return (
    <div className="flex">
      <span className="bg-white text-black text-3xl rounded-full w-14 h-14 flex justify-center items-center">{index}</span>
      <div className="ml-10 text-4xl">{children}</div>
    </div>
  )
}

const EventPoints: FC<IEventPointsProps> = () => {
  const navigate = useNavigate()

  // Idle redirect
  const idleTimeoutHandler = useCallback(() => {
    navigate('/')
  }, [navigate])

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: idleTimeoutHandler
  })

  useEffect(() => {
    trackEvent('QR Page Reached')
  }, [])

  return (
    <Layout>
      <Header />

      <Content topPadding={false} className="pt-16">
        <h1 className="text-7.5xl mb-8 tracking-wider">Carbon Calculator app</h1>

        <p className="text-3xl">Check out our cardholder app in the demo area</p>

        <img src={CarbonApp} alt="Carbon App" className="object-contain w-6/12 mx-auto mt-10 aspect-[695/657]" />

        <ActionButton className="w-1/2 mx-auto my-10" onClick={() => navigate('/')}>FINISH</ActionButton>
      </Content>

      <Content topPadding={false} className="pt-16 bg-[#dddddd]">
        <h1 className="text-7.5xl mb-12 tracking-wider">To get event points</h1>

        <section className="flex">

          <img src={EventApp} alt="EventApp App" className="object-contain w-1/5 aspect-[282/569]" />

          <div className="flex flex-col justify-between grow ml-20 font-light">
            <BulletPoint index={1}>
              Go to exhibit tab in the event app
            </BulletPoint>

            <BulletPoint index={2}>
              Select booth name: <br />
              <strong className="text-3xl leading-loose block">MASTERCARD CARBON CALCULATOR</strong>
            </BulletPoint>

            <BulletPoint index={3}>
              Input code: <br />
              <strong className="text-3xl leading-loose block">CC22</strong>
            </BulletPoint>
          </div>
        </section>
      </Content>

      <Footer />
    </Layout>
  );
};

export default EventPoints;
