import styles from '../styles/layout.module.css'

export interface IContentProps  {
  topPadding?: boolean,
  leftPadding?: boolean,
  rightPadding?: boolean,
  bottomPadding?: boolean,
  className?: string,
}

const Content: React.FC<IContentProps> = ({
  topPadding = true,
  leftPadding = true,
  rightPadding = true,
  bottomPadding = true,
  className = '',
  children,
  ...props
}): JSX.Element => {
  return (
    <section className={`flex bg-[#efeee8] flex-col grow h-full
      ${topPadding ? styles['layout-top-padding'] : ''}
      ${leftPadding ? styles['layout-left-padding'] : ''}
      ${rightPadding ? styles['layout-right-padding'] : ''}
      ${bottomPadding ? styles['layout-bottom-padding'] : ''}
      ${className}
    `}
      {...props}
    >
      {children}
    </section>
  )
}

export default Content