export enum TransportType {
  Flight = 'Flight',
  Taxi = 'Taxi',
}
export interface ITransportConfig {
  type: TransportType,
  name: string,
  co2: number,
  trees: number,
  donation: number,
}

const FlightsConfig: ITransportConfig[] = [
  {
    type: TransportType.Flight,
    name: 'Andean',
    co2: 354,
    trees: 18,
    donation: 36,
  },
  {
    type: TransportType.Flight,
    name: 'Brazil',
    co2: 489,
    trees: 24,
    donation: 48,
  },
  {
    type: TransportType.Flight,
    name: 'Caribbean',
    co2: 192,
    trees: 10,
    donation: 20,
  },
  {
    type: TransportType.Flight,
    name: 'Central America',
    co2: 410,
    trees: 20,
    donation: 40,
  },
  {
    type: TransportType.Flight,
    name: 'Mexico',
    co2: 273,
    trees: 14,
    donation: 28,
  },
  {
    type: TransportType.Flight,
    name: 'Southern Cone',
    co2: 804,
    trees: 40,
    donation: 80,
  },
]

const TaxiConfig: ITransportConfig = {
  type: TransportType.Taxi,
  name: 'Taxi',
  co2: 10,
  trees: 1,
  donation: 2,
}

export {
  FlightsConfig,
  TaxiConfig,
}