import { ReactComponent as NextIcon } from "../assets/images/icon-arrow-right.svg"

export function IconRight() {
  return <NextIcon fill="#141413" />
}

export interface ISelectionButtonProps {
  title: string,
  content?: JSX.Element | string | null,
  showIcon?: boolean,
  onClick?: () => void,
  selected?: boolean,
  className?: string,
}

const SelectionButton: React.FC<ISelectionButtonProps> = ({
  title,
  onClick,
  content = null,
  showIcon = true,
  selected = false,
  className = '',
}): JSX.Element => {
  return (
    <button className={`bg-white flex flex-row justify-between items-center px-6 py-1
      min-h-[9rem] w-full shadow-xl rounded-2xl
      transition-transform ${selected ? 'border-4 border-eco-green scale-105' : ''} ${className}`}
      onClick={() => onClick && onClick()}
    >
      <span className="text-left">
        <span className="uppercase font-light text-3xl ml-1">
          {title}
        </span>

        {content}
      </span>

      {showIcon ? <IconRight /> : null}
    </button>
  )
}

export default SelectionButton