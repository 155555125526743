import LogoImg from '../assets/images/mc-logo.webp'
import styles from '../styles/layout.module.css'

export interface IHeaderProps  {
  
}

const Header: React.FC<IHeaderProps> = ({
  ...props
}): JSX.Element => {
  return (
    <header className={`bg-black h-[180px] ${styles['layout-left-padding']} ${styles['layout-right-padding']}
      ${styles['header-padding-y']} `} {...props}>
      <img src={LogoImg} alt="MC Logo" className="h-full" />
    </header>
  )
}

export default Header