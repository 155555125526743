import { FC, useCallback, useContext, useEffect, useState, } from "react"
import { useNavigate } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer'
import { trackEvent } from "../utils/analytics"
import Layout from "../shared/Layout"
import Content from "../shared/Content"
import Header from "../shared/Header"
import Footer from "../shared/Footer"
import ActionButton, { ActionButtonTypes } from "../shared/ActionButton"
import PaymentModal, { PaymentModalType } from "../shared/PaymentModal"

import TreeImg from "../assets/images/tree.webp"
import MCAudio from "../assets/audio/MC_SONIC_SOUND.mp3"
import { Context } from "../context/GlobalStateContext"
import useStripe from "../hooks/useStripe"
import { cancelCollectPaymentMethod } from "../utils/stripe"

const idleTimeout = Number(process.env.REACT_APP_IDLE_TIMEOUT)
const paymentIdleTimeout = Number(process.env.REACT_APP_PAYMENT_IDLE_TIMEOUT)

export interface IPlantTreeProps { }

function formatAmount(amount: number): string {
  return `$${amount}`
}

const PlantTree: FC<IPlantTreeProps> = () => {
  const navigate = useNavigate()
  const { state } = useContext(Context)

  const {
    state: stripeState,
    pay,
  } = useStripe()
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false)
  const [paymentModalType, setPaymentModalType] = useState<PaymentModalType>(PaymentModalType.Loading)
  const [paymentModalMessage, setPaymentModalMessage] = useState<string>('')
  const [donationAmount, setDonationAmount] = useState<number>(0)

  useEffect(() => {
    if (!state.travelType) {
      navigate('/')
    }
  }, [state, navigate])

  // Idle redirect
  const idleTimeoutHandler = useCallback(() => {
    console.log('Idle timeout')
    navigate('/')
  }, [navigate])

  const {
    start: startIdleTimer,
    pause: pauseIdleTimer,
  } = useIdleTimer({
    timeout: idleTimeout,
    onIdle: idleTimeoutHandler
  })

  // Payment idle redirect
  const paymentIdleTimeoutHandler = useCallback(() => {
    console.log(`Payment idle timeout: ${paymentIdleTimeout}ms elapsed`)

    cancelCollectPaymentMethod()
      .catch((error) => console.error('Cancel collect payment method failed', error))
    idleTimeoutHandler()
  }, [idleTimeoutHandler])

  const {
    start: startPaymentIdleTimer,
    pause: pausePaymentIdleTimer,
  } = useIdleTimer({
    timeout: paymentIdleTimeout,
    onIdle: paymentIdleTimeoutHandler,
    startManually: true
  })

  const amountSelect = useCallback(async (amount: number) => {
    console.log('Selected donation amount: %d', amount)

    pauseIdleTimer()
    startPaymentIdleTimer()

    setDonationAmount(amount)
    setPaymentModalType(PaymentModalType.Loading)
    setPaymentModalOpen(true)

    pay(amount)

    trackEvent('Selected Donation Amount', { props: { amount } })
  }, [pay, pauseIdleTimer, startPaymentIdleTimer]);

  useEffect(() => {
    let timeout: NodeJS.Timeout

    switch (stripeState.status) {
      case 'tap':
        setPaymentModalType(PaymentModalType.TapCard)
        break
        
      case 'success':
        pausePaymentIdleTimer()
        pauseIdleTimer()

        setPaymentModalType(PaymentModalType.Success)

        trackEvent('Donated Amount', { props: { amount: donationAmount } })

        if (stripeState.brand === 'mastercard') {
          const audio = new Audio(MCAudio)
          audio.play()
        }

        setTimeout(() => {
          navigate('/thank-you')
        }, 2500);
        break

      case 'error':
        pausePaymentIdleTimer()
        startIdleTimer()

        setPaymentModalType(PaymentModalType.Error)
        setPaymentModalMessage(stripeState.error)

        trackEvent('Payment Error', { props: { error: stripeState.error } })

        timeout = setTimeout(() => {
          setPaymentModalOpen(false)
        }, 5000);
        break
      
      case 'loading':
        setPaymentModalType(PaymentModalType.Loading)
        break
    }

    return () => clearTimeout(timeout)
  }, [stripeState, navigate, donationAmount, pausePaymentIdleTimer, startIdleTimer, pauseIdleTimer])

  const skip = useCallback(() => {
    navigate('/event-points')
  }, [navigate])

  return (
    <Layout>
      <Header />

      <Content topPadding={false} className="pt-16">
        <h1 className="text-7.5xl mb-16 tracking-wider">Plant a tree</h1>

        <section className="flex flex-row items-center bg-eco-white p-8 rounded-3xl">
          <div className="grow flex mr-1 items-center h-full text-4xl leading-relaxed tracking-wide"
          >
            Start Something Priceless and contribute<br />
            to the future of our planet by planting a<br />
            tree today as we seek to restore 100<br />
            million trees by 2025. 
          </div>
          <img src={TreeImg} alt="Tree" className="object-contain w-48 aspect-square" />
        </section>

        <section className="flex flex-row left-border bg-eco-white mt-8
          rounded-3xl text-4xl leading-snug tracking-wide overflow-hidden"
        >
          <span className="py-6 px-8 bg-eco-white">
            Donate $10 or more and Mastercard will match your donation up to a total of $10,000
          </span>
        </section>

        <section className="mt-12 grid grid-cols-4 gap-x-12 gap-y-8">
          <ActionButton className="py-8 col-start-1 col-span-2" onClick={() => amountSelect(state.donationAmount)}>
            {formatAmount(state.donationAmount)} = {state.trees} {state.trees === 1 ? 'tree' : 'trees'}
          </ActionButton>
          <span className="col-start-3 col-span-2 bg-eco-white text-3xl leading-none rounded-2xl
            w-8/12 px-10 text-center flex items-center arrow-left"
          >
            Your estimated carbon footprint
          </span>

          <ActionButton
            className="py-8 col-start-1 col-span-2 mt-4"
            type={ActionButtonTypes.Secondary}
            onClick={() => amountSelect(2)}>$2 = 1 tree</ActionButton>
          <ActionButton
            className="py-8 col-start-3 col-span-2 mt-4"
            type={ActionButtonTypes.Secondary}
            onClick={() => amountSelect(20)}>$20 = 10 trees</ActionButton>

          <ActionButton
            className="py-8 col-start-1 col-span-2"
            type={ActionButtonTypes.Secondary}
            onClick={() => amountSelect(4)}>$4 = 2 trees</ActionButton>
          <ActionButton
            className="py-8 col-start-3 col-span-2"
            type={ActionButtonTypes.Secondary}
            onClick={() => amountSelect(50)}>$50 = 25 trees</ActionButton>
          <ActionButton
            className="py-8 col-start-1 col-span-2"
            type={ActionButtonTypes.Secondary}
            onClick={() => amountSelect(10)}>$10 = 5 trees</ActionButton>
          <ActionButton
            className="py-8 col-start-3 col-span-2"
            type={ActionButtonTypes.Secondary}
            onClick={() => amountSelect(100)}>$100 = 50 trees</ActionButton>
          <ActionButton
            className="py-8 col-start-2 col-span-2"
            type={ActionButtonTypes.Secondary}
            onClick={skip}>SKIP</ActionButton>
        </section>

        <footer className="grow flex flex-col justify-end">
          <div className="text-lg">
            Cost per tree is approximate. Your donation will be made to Conservation International,
            with proceeds granted to forest restoration projects fulfilled by both Conservation
            International and World Resource Institute.
          </div>
        </footer>

        <PaymentModal
          open={paymentModalOpen}
          type={paymentModalType}
          message={paymentModalMessage}
        />
      </Content>

      <Footer />
    </Layout>
  );
};

export default PlantTree
