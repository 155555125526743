import { FC, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { discoverReaders, connectReaderToTerminal } from "./utils/stripe";
import Store from "./context/GlobalStateContext";
import Home from "./components/Home";
import Options from "./components/Options";
import Flight from "./components/Flight";
import Taxi from "./components/Taxi";
import Footprint from "./components/Footprint";
import PlanetCoalition from "./components/PlanetCoalition";
import PlantTree from "./components/PlantTree";
import ThankYou from "./components/ThankYou";
import EventPoints from "./components/EventPoints";

interface IAppProps {}

const App: FC<IAppProps> = () => {
  useEffect(() => {
    console.debug('App startup, trying to connect to Stripe Terminal')

    async function load() {
      try {
        const [reader] = await discoverReaders()
        await connectReaderToTerminal(reader)
      } catch (error) {
        console.error('Initial Stripe comm error', error)

        if (process.env.NODE_ENV === 'production') {
          console.warn('Redirecting to home page in 10 seconds')

          setTimeout(() => {
            window.location.href = '/'
          }, 10000)
        } else {
          console.warn('Ignoring error in development mode')
        }
      }
    }

    load()
  }, [])

  return (
    <Store>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/options" element={<Options />} />
        <Route path="/flight" element={<Flight />} />
        <Route path="/taxi" element={<Taxi />} />
        <Route path="/footprint" element={<Footprint />} />
        <Route path="/planet-coalition" element={<PlanetCoalition />} />
        <Route path="/plant-a-tree" element={<PlantTree />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/event-points" element={<EventPoints />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Store>
  );
};

export default App;
