export interface ILayoutProps  {
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const Layout: React.FC<ILayoutProps> = ({
  children,
  ...props
}): JSX.Element => {
  return (
    <div className="flex flex-col h-full" {...props}>
      {children}
    </div>
  )
}

export default Layout