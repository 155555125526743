import { FC, useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer'
import { trackEvent } from "../utils/analytics"
import { TravelType } from "../enums"
import SelectionButton from "../shared/SelectionButton"
import Layout from "../shared/Layout"
import Content from "../shared/Content"
import Header from "../shared/Header"
import Footer from "../shared/Footer"

import FlightImg from "../assets/images/flight.webp"
import { Context, GlobalStateReducerTypes } from "../context/GlobalStateContext"

const idleTimeout = Number(process.env.REACT_APP_IDLE_TIMEOUT)

interface IOptionsProps { }

const Options: FC<IOptionsProps> = () => {
  const navigate = useNavigate()

  // Idle redirect
  const idleTimeoutHandler = useCallback(() => {
    navigate('/')
  }, [navigate])

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: idleTimeoutHandler
  })

  const { dispatch } = useContext(Context);

  function selection(type: TravelType) {
    console.log('Selected transporation: %s', type);

    dispatch({
      type: GlobalStateReducerTypes.SetTravelType,
      payload: type,
    })

    trackEvent('Selected Option', { props: { type } })

    switch(type) {
      case TravelType.Flight:
      case TravelType.FlightAndTaxi:
        navigate('/flight');
        break;
      case TravelType.Taxi:
        navigate('/taxi');
        break;
    } 
  }

  return (
    <Layout>
      <Header />

      <Content>
        <h1 className="text-7.5xl mb-16 tracking-wider">How did you get to this<br />event?</h1>

        <section className="flex flex-row justify-between pr-2 w-full">
          <section className="w-96">
            <SelectionButton
              title="Flight + Taxi"
              onClick={() => { selection(TravelType.FlightAndTaxi) }}
              className="mb-12"
            />
            <SelectionButton
              title="Flight Only"
              onClick={() => { selection(TravelType.Flight) }}
              className="mb-12"
            />
            <SelectionButton
              title="Car / Taxi Only"
              onClick={() => { selection(TravelType.Taxi) }}
            />
          </section>

          <img src={FlightImg} alt="Travel Type" className="object-contain -mr-12 aspect-[559/528]" />
        </section>

      </Content>

      <Footer />
    </Layout>
  );
};

export default Options;
