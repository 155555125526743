import { FC, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer'
import Layout from "../shared/Layout"
import Content from "../shared/Content"
import Header from "../shared/Header"
import Footer from "../shared/Footer"
import ActionButton from "../shared/ActionButton"

import PromoImg from "../assets/images/man-tree.webp"
import ConservationLogo from "../assets/images/conservations-logo.webp"
import MapVideo from "../assets/videos/map.webm"

const idleTimeout = Number(process.env.REACT_APP_IDLE_TIMEOUT)

interface IPlanetCoalitionProps { }

const PlanetCoalition: FC<IPlanetCoalitionProps> = () => {
  const navigate = useNavigate()

  // Idle redirect
  const idleTimeoutHandler = useCallback(() => {
    navigate('/')
  }, [navigate])

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: idleTimeoutHandler
  })

  const selection = useCallback(() => {
    navigate('/plant-a-tree')
  }, [navigate]);

  return (
    <Layout>
      <Header />

      <Content topPadding={false} leftPadding={false} rightPadding={false}>
        <img src={PromoImg} alt="Planet Coalition" className="aspect-[540/251]" />

        <section className="px-24 pt-16">
          <h2 className="text-6xl mb-8 tracking-wider">Priceless Planet Coalition</h2>

          <p className="text-3xl leading-snug tracking-wide">
            With 18 reforestation projects across six continents,<br />
            we’re accelerating progress toward our goal of 100<br />
            million trees by 2025
          </p>

          <section className="flex flex-row mt-16">
            <div className="w-2/5">
              <img src={ConservationLogo} alt="Logo" className="w-10/12 mb-4 aspect-[211/68]" />
              
              <strong className="block text-lg">Asia Pacific</strong>
              <small className="block text-[12px]">Australia, Cambodia, China, India, Philippines</small>
              <strong className="block mt-2 text-lg">Latin America & Caribbean</strong>
              <small className="block text-[12px]">Brazil, Mexico, Colombia, Guatemala</small>
              <strong className="block mt-2 text-lg">Europe</strong>
              <small className="block text-[12px]">France, Portugal, Spain, Scotland</small>
              <strong className="block mt-2 text-lg">Middle East & Africa</strong>
              <small className="block text-[12px]">Madagascar, Democratic Republic of Congo,
              Kenya, Malawi, United Arab Emirates</small>
              <strong className="block mt-2 text-lg">North America</strong>
              <small className="block text-[12px]">United States</small>
            </div>

            <div className="w-full">
              <video src={MapVideo} className="w-auto" autoPlay loop muted />
            </div>
          </section>

          <section className="mt-16 w-full text-center">
            <ActionButton className="w-1/2" onClick={() => selection()}>NEXT</ActionButton>
          </section>
        </section>

      </Content>

      <Footer />
    </Layout>
  );
};

export default PlanetCoalition
